import "../styles/ProductPage.css";

import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Product } from "../models/Product";
import { ProductInfo } from "../models/ProductInfo";
import { getAllProductsByCategory } from "../services/products.service";

export function ProductPage() {
  const location = useLocation();

  const { title, category, photo } = location.state
    ? (location.state as ProductInfo)
    : { title: "", category: "", photo: "" };
  const [products, setProducts] = useState<Product[][]>([]);

  useMemo(async () => {
    const productSets = [];
    const data = (await getAllProductsByCategory(category)).data;
    productSets.push(data);
    setProducts(productSets);
  }, [category]);

  return (
    <div className="product-page">
      <div className="products">
        <h1 className="productTitle">{title}</h1>
        <h2 className="productVendor">
          {products[0] && products[0][0] ? products[0][0].brand : ""}
        </h2>
        <div className="productTables">
          {products.map((productList, index) => (
            <table key={index} className="productList">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Cost</th>
                  <th>Unit</th>
                </tr>
              </thead>
              <tbody>
                {productList.map((product, index) => (
                  <tr key={index}>
                    <td>{product.name}</td>
                    <td>${Number(product.cost).toFixed(2)}</td>
                    <td>
                      {product.unit !== "ea"
                        ? `per ${product.unit}`
                        : product.unit}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))}
        </div>
        <img
          className="productImage"
          src={process.env.PUBLIC_URL + photo}
          alt="it's a me"
        ></img>
        <a href="https://store.mifillosophy.com" id="shop">
          Shop Now
        </a>
      </div>
    </div>
  );
}
