import { useState } from "react";
import { Link } from "react-router-dom";
import { ProductInfo } from "../models/ProductInfo";
import "../styles/Products.css";

export function Products() {
  const [items] = useState<ProductInfo[]>([
    {
      title: "Pasta",
      path: "/products/pasta",
      category: "pasta",
      photo: "/pasta.jpg",
      headerPhoto: "/noodles.jpg",
    },
    {
      title: "Rice & Beans",
      path: "/products/beans-and-rice",
      category: "beans-and-rice",
      photo: "/rices.jpg",
      headerPhoto: "/riceheader.jpg",
    },
    {
      title: "Nuts & Seeds",
      path: "/products/nuts-and-seeds",
      category: "nuts-and-seeds",
      photo: "/nutsandseeds.jpg",
      headerPhoto: "/nut.jpg",
    },
    {
      title: "Baking Ingredients",
      path: "/products/baking-ingredients",
      category: "baking-ingredients",
      photo: "/baking.jpg",
      headerPhoto: "/bakingflour.jpg",
    },
    {
      title: "Extracts",
      path: "/products/extracts",
      category: "extracts",
      photo: "/extracts.jpg",
      headerPhoto: "/extract.jpg",
    },
    {
      title: "Spices",
      path: "/products/spices",
      category: "spices",
      photo: "/spices.jpg",
      headerPhoto: "/spice.jpg",
    },
    {
      title: "Dried Fruits",
      path: "/products/dried-fruits",
      category: "dried-fruits",
      photo: "/fruit.jpeg",
      headerPhoto: "/driedfruits.jpg",
    },
    {
      title: "Sauces",
      path: "/products/sauce",
      category: "sauce",
      photo: "/saucebackground.jpg",
      headerPhoto: "/saucy.jpg",
    },
    {
      title: "Oils & Vinegars",
      path: "/products/oil-and-vinegar",
      category: "oil-and-vinegar",
      photo: "/oils.jpg",
      headerPhoto: "/oil.jpg",
    },
    {
      title: "Tea & Coffee",
      path: "/products/tea-and-coffee",
      category: "tea-and-coffee",
      photo: "/Tea.jpg",
      headerPhoto: "/coffee.jpg",
    },
    {
      title: "Home Goods",
      path: "/products/home-goods",
      category: "home-goods",
      photo: "/bamboo.jpg",
      headerPhoto: "/homegoods.jpg",
    },
    {
      title: "Reusables",
      path: "/products/reusables",
      category: "reusables",
      photo: "/bamboo.jpg",
      headerPhoto: "/reusables.jpg",
    },
    {
      title: "Containers",
      path: "/products/containers",
      category: "jars-and--misc-items",
      photo: "/jars.jpg",
      headerPhoto: "/containers.jpg",
    },
    {
      title: "Soaps",
      path: "/products/soaps",
      category: "soap",
      photo: "/soap.jpg",
      headerPhoto: "/soapsheader.jpg",
    },
    {
      title: "Essential Oils",
      path: "/products/essential-oils",
      category: "essential-oils",
      photo: "/essentialoil.png",
      headerPhoto: "/essentialoils.jpg",
    },
    {
      title: "Refrigerated Goods",
      path: "/products/essential-oils",
      category: "refrigerated",
      photo: "/fridge.jpg",
      headerPhoto: "/fridge.jpg",
    },
  ]);

  return (
    <div className="productsdiv">
      <div className="product">
        {items.map((item, index) => (
          <div className="individualproduct" key={index}>
            <Link to={item.path} state={item} className="productHeader">
              <img className="productPage" src={item.headerPhoto} alt="" />
              {item.title}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
