import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import barsIcon from "./bars-solid.svg";

export function Header() {
  let navRef = React.createRef<HTMLDivElement>();
  /* Open */
  function openNav() {
    navRef.current!.style.height = "100%";
  }
  /* Close */
  function closeNav() {
    navRef.current!.style.height = "1px";
  }
  return (
    <div id="header" className="myNav">
      <a href="/">
        <img src="ashlogo.svg" alt="" className="logo" />
      </a>
      <div className="nav">
        <Link to="/" className="headerText">
          Home{" "}
        </Link>
        <p className="headerText">|</p>
        <Link to="/howitworks" className="headerText">
          How It Works
        </Link>
        <p className="headerText">|</p>
        <Link to="/products" className="headerText">
          {" "}
          Products{" "}
        </Link>
        <p className="headerText">|</p>
        <a href="https://store.mifillosophy.com" className="headerText">
          {" "}
          Shop{" "}
        </a>
        <p className="headerText">|</p>
        <Link to="/contactinfo" className="headerText">
          Contact Us
        </Link>
      </div>
      {/* <!-- The overlay --> */}
      <div ref={navRef} id="myNav" className="overlay">
        <button className="closebtn" onClick={() => closeNav()}>
          &times;
        </button>
        <div className="overlay-content">
          <a href="/">Home</a>
          <a href="/Howitworks">How It Works</a>
          <a href="/products">Products</a>
          <a href="https://store.mifillosophy.com">Shop</a>
          <a href="/contactinfo">Contact Us</a>
        </div>
      </div>
      <span className="burgerMenu" onClick={() => openNav()}>
        <img id="barsmenu" style={{ width: "30px" }} src={barsIcon} alt="" />
      </span>
    </div>
  );
}
