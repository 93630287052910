import React from "react";
import "./App.css";
import { HomePage } from "./components/HomePage";
import { Header } from "./components/header/Header";
import { ContactInfo } from "./components/ContactInfo";
import { Products } from "./components/Products";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HowItWorks from "./components/HowItWorks";
import { ProductPage } from "./components/ProductPage";
import { Footer } from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/products" element={<Products />} />
          <Route path="/howitworks" element={<HowItWorks />} />
          <Route path="/contactinfo" element={<ContactInfo />} />
          <Route path="/products/*" element={<ProductPage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
