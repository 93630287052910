import '../styles/InfoCard.css';

interface InfoCardProps {
  title: string;
  content: string;
  side?: 'left' | 'right';
  imageSrc?: string;
}

export default function InfoCard({ title, content, side = 'right', imageSrc }: InfoCardProps) {
  return (
    <div className="info-card">
      {side === 'left' && (
        <div className="image-section">
          <img className="highlighted-img" src={imageSrc} alt="" />
        </div>
      )}
      <div className="text-section">
        <h1 className="title">{title}</h1>
        <p className="content">{content}</p>
      </div>
      {side === 'right' && (
        <div className="image-section">
          <img className="highlighted-img" src={imageSrc} alt="" />
        </div>
      )}
    </div>
  );
}
