import "../styles/HomePage.css";
import InfoCard from "./InfoCard";
export function HomePage() {
  const infoProps = {
    title: "About MI—fillosophy",
    content: ` We are a low waste bulk store that gives you the ability to refill
                your used or new jars. Our goal is to make refill stores more
                accessible to help reduce single use plastics. We offer a variety of
                organic foods to choose from!`,
    imageSrc: "homepage.jpg",
  };

  return (
    <div id="home">
      {/* <img id="example" src="headerbanner.jpg" alt="" /> */}
      <InfoCard {...infoProps} />
      <div className="mostpopular">
        <h1 id="populartitle">Recent Favs</h1>

        <div className="popularitems">
          <div className="item">
            <img id="favpopcorn" src="favpopcorn.jpg" alt="" />
            <div className="description">
              <p className="item"> Organic Popcorn</p>
              <p>$0.26 per oz</p>
            </div>
          </div>
          <div className="item">
            <img id="favchocolatechips" src="favchoco.jpg" alt="" />
            <div className="description">
              <p className="item">Organic Chocolate Chips</p>
              <p>$1.35 per oz</p>
            </div>
          </div>
          <div className="item">
            <img id="favsugar" src="favsugar.jpg" alt="" />
            <div className="description">
              <p className="item">Organic Cane Sugar</p>
              <p>$0.35per oz</p>
            </div>
          </div>
          <div className="item">
            <img id="favrice" src="favrice.jpg" alt="" />
            <div className="description">
              <p className="item">Organic Brown Long Grain Rice</p>
              <p>$0.45 per oz</p>
            </div>
          </div>
          <div className="item">
            <img id="favflour" src="favflour.jpg" alt="" />
            <div className="description">
              <p className="item">Organic All Purpose Flour</p>
              <p>$0.22 per oz</p>
            </div>
          </div>
          <div className="item">
            <img id="favessentialoil" src="favessentialoil.jpg" alt="" />
            <div className="description">
              <p className="item">Organic Essential Oils</p>
              <p>$10-15 each</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
