import "../styles/Footer.css";

export function Footer() {
  return (
    <div className="Footer">
      <div className="socialTags">
        <i className="fa-brands fa-facebook-square"></i>
        <i className="fa-brands fa-instagram"></i>
        <i className="fa-brands fa-twitter"></i>
      </div>
      <p>Certified Organic</p>
      <p>Mi-Fillosophy 2023</p>
    </div>
  );
}
