import { FillIcon, FullIcon, WeightIcon } from "../icons";
import "../styles/HowItWorks.css";

export default function HowItWorks() {
  return (
    <div className="howitworkstotalpage">
      <div id="howItWorksDiv">
        <h1 id="howItWorks">How It Works</h1>
        <div id="howItWorksComponents">
          <div id="weighDiv">
            <WeightIcon className="weight" />
            <div id="weighDivInfo">
              <h1 id="headerHIW">WEIGH</h1>
              <p className="para">
                Weigh & label your container's tare weight with our scale
              </p>
            </div>
          </div>
          <div id="fillDiv">
            <FillIcon className="fillJar" />
            <div id="refillInfo">
              <h1 id="headerHIW">rEFILL</h1>
              <p className="para">
                Add as much or as little as you need to the container
              </p>
            </div>
          </div>
          <div id="fullDiv">
            <FullIcon className="fullJar" />
            <div id="checkout">
              <h1 id="headerHIW">CHECKOUT</h1>
              <p className="para">
                By deducting the weight of your container, you'll pay only for
                the products you choose
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
