import '../styles/ContactInfo.css';

export function ContactInfo() {
  const hoursOfOperation = [
    { day: 'Monday', hours: 'Closed' },
    { day: 'Tuesday', hours: '10 AM-6 PM' },
    { day: 'Wednesday', hours: '10 AM-6 PM' },
    { day: 'Thursday', hours: '10 AM-6 PM' },
    { day: 'Friday', hours: '11 AM-7 PM' },
    { day: 'Saturday', hours: '10 AM-5 PM' },
    { day: 'Sunday', hours: 'Closed' },
  ];
  return (
    <div id="contactInfo">
      <h1>Get in touch</h1>
      <div className="details">
        <div className="hours">
          <h2>Hours of Operation</h2>
          <table>
            <thead>
              <tr>
                <th>Day</th>
                <th>Hours</th>
              </tr>
            </thead>
            <tbody>
              {hoursOfOperation.map((weekday) => (
                <tr>
                  <td>{weekday.day}</td>
                  <td>{weekday.hours}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="line"></div>
        <div className="contactMethods">
          <div className="method">
            <h3>Call Us</h3>
            <div className="inset">
              <p>Put us on your favorites!</p>
              <a className="phone" href="tel:734-667-2319">
                <img className="icon" src="phone.svg" alt="Phone Icon" />
                734-667-2319
              </a>
            </div>
          </div>
          <div className="method">
            <h3>Email Us</h3>
            <div className="inset">
              <p>Reach out to us with any questions via email</p>
              <a className="email" href="mailto:MIFillosophy@gmail.com">
                <img className="icon" src="email.svg" alt="Email icon" />
                MIFillosophy@gmail.com
              </a>
            </div>
          </div>
          <div className="method">
            <h3>Visit Us</h3>
            <div className="inset">
              <p>Stop by for a visit!</p>
              <a className="address" href="https://goo.gl/maps/ru7nNYi8mhDKkrqB8">
                <img className="icon" src="location.svg" alt="Location pin" />
                1170 Ann Arbor Road Plymouth Michigan, 48170
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mapContainer">
        <iframe
          id="map"
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2948.2446669566107!2d-83.47874187439973!3d42.35862477662898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b5342f51ad7ed%3A0x5b52bae7d898a6e4!2sMI%20Fillosophy!5e0!3m2!1sen!2sus!4v1680382525817!5m2!1sen!2sus"
          allowFullScreen={false}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}
